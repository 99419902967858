<template>
    <div class="tabs">
        <ul class="tabs-ul">
            <li class="tabs-li" v-for="(tab, index) in tabs" :key="index" :class="{ active: activeIndex === index }"
                @mouseover="activateTab(index)">
                {{ tab.label }}
            </li>
        </ul>
        <div class="tab-content">
            <div v-for="(tab, index) in tabs" :key="index" v-show="activeIndex === index">
                <ul class="tab-content-ul">
                    <li class="tab-content-li" v-for="(item, index) in tab.content" :key="index">
                        {{ "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + item }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            activeIndex: 0, // 当前激活的 tab 的索引
            tabs: [ // tab 数据
                {
                    label: '公司文化',
                    content:
                        [
                            '文化是公司的灵魂和未来​，成为中国供应链环节提供优质的人才和解决方案提供者。物流是血液,信息流是中枢神经,人才流是根本。 三位一体，才能为实体经济降本增效，达到切实的效果。',
                            '企业的使命​：打造规范、精细、实效的供应链信息中枢环境以及工具。 我们将为中华民族的产业崛起而奋斗。',
                            '我们企业的价值观​：创新：拼搏进取 创造价值 。信义：诚信果敢 博爱谦逊。 分享：事业共创 利益共享。 气度：海纳百川 放眼天下。 担当：敢于承担 舍我其谁。',
                            '追求目标，信念为上；长期合作，信任为上；团队合作，信心为上；透明管理，信用为上。期待您能加入我们的团队，成为我们的合作伙伴！'
                        ]
                },
                {
                    label: '公司概况',
                    content:
                        [
                            '打造符合交通运输部的标准化货运信息系统， 解决有车承运和无车承运供应链模式中专业的供应链问题， 同时助力全国传统物流企业向 网络化、信息化、标准化、集约化和互联网化方向的 转型升级，优化物流成本，助力经济发展。',
                            '集仓储、配载、大件运输、长途运输、分拨等业务为一体的专业、综合型供应链物流企业，以现代化管理的经营思想和理念，严格有效的管理方法使公司在运输市场的占有一定的份额和信誉度。',
                        ]
                },
                {
                    label: '平台背景',
                    content:
                        [
                            '本软件平台站在产业发展的高度，以先进IT技术为核心，以物联网和智能物流为基础，以优化配置、资源整合为手段，着力打造一个物流信息应用服务的大平台，全方位服务于全国的交通物流发展，服务于全国的经济发展。',
                            '通过物流信息标准化建设、先进技术引导和行政资源社会化，吸引制造企业、第三方物流企业进入平台，进行商业运营，有效地解决了打造供应链信息平台容易，市场应用推广难的难题。',
                            '本平台的科学建设与高效运营，将加快物流业向大交通、大物流迈进的步伐，成为苏州乃至全省、全国物流领域的一个重要平台，一张形象“名片”。',
                        ]
                }
            ]
        };
    },
    methods: {
        // 激活指定索引的 tab
        activateTab(index) {
            this.activeIndex = index;
        }
    }
};
</script>
  
<style lang="less" scoped>
/* 样式可以根据需求自行修改 */
.tabs .tabs-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 5vh;
}

.tabs .tabs-li {
    color: #fff;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    background: #a9a9a9;
    display: inline-block;
    margin-right: 10px;
    padding: 0px 24px;
    cursor: pointer;
    border-radius: 36px;
    transition: 0.1s;
}

.tabs .tabs-li.active {
    color: #fff;
    background: rgb(1, 113, 138);
    box-shadow: 0 0 3px rgb(1, 113, 138);
}

.tab-content {
    // border: 1px solid #ccc;
    padding: 20px;
    max-width: 1200px;

    .tab-content-ul {
        .tab-content-li {
            line-height: 48px;
            color: #888888;
        }
    }
}
</style>