// 必须引入封装好的 axios 实例方法,才可发请求
import { reqCode, reqUserLogin, reqAdminLogin, reqRegister, reqForgetPassowrd } from '../../api/index'
export default {
    namespaced: true,
    actions: {
        async getCodeId({ commit }, email) {
            let result = await reqCode(email)
            // commit('GETMESSAGE', result.message)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETCODEID', result.data.codeId)
                commit('GETMESSAGE', "验证码已发送,请及时填写!")
            } else {
                commit('GETMESSAGE', "验证码发送失败,请稍后再试!")
            }
        },
        async userLogin({ commit }, user) {
            let result = await reqUserLogin(user)
            commit('GETSTATUS', result.status)
            commit('GETLOGINSTATUS', result.status)
            if (result.status == 200) {
                commit('GETTOKEN', result.token)
                commit('GETRESOURCES', [])
                commit('GETMESSAGE', "登录成功!")
                commit('CHANGEUSERLOGINSTATUS', true)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async adminLogin({ commit }, user) {
            let result = await reqAdminLogin(user)
            commit('GETSTATUS', result.status)
            commit('GETLOGINSTATUS', result.status)
            if (result.status == 200) {
                commit('GETRESOURCES', result.data)
                commit('GETTOKEN', result.token)
                commit('GETMESSAGE', "登录成功!")
                commit('CHANGEADMINLOGINSTATUS', true)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async userRegister({ commit }, user) {
            let result = await reqRegister(user)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', "注册成功!")
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async forgetPassowrd({ commit }, user) {
            let result = await reqForgetPassowrd(user)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', "找回账号成功!")
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async changeLoginStatus({ commit }) {
            commit('CHANGELOGINSTATUS', false)
        }
    },
    mutations: {
        GETCODEID(state, codeId) {
            if (codeId) {
                sessionStorage.setItem('codeId', codeId)
                state.codeId = codeId
            }
        },
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETLOGINSTATUS(state, loginStatus) {
            localStorage.setItem('loginStatus', loginStatus)
            state.loginStatus = loginStatus
        },
        GETTOKEN(state, token) {
            if (token) {
                localStorage.setItem('token', token)
                state.token = token
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        CHANGEADMINLOGINSTATUS(state, isAdminLogin) {
            localStorage.setItem('isAdminLogin', isAdminLogin)
            state.isAdminLogin = isAdminLogin
        },
        CHANGEUSERLOGINSTATUS(state, isUserLogin) {
            localStorage.setItem('isUserLogin', isUserLogin)
            state.isUserLogin = isUserLogin
        },
        GETRESOURCES(state, resources) {
            // if (resources) {
            localStorage.setItem('resources', JSON.stringify(resources))
            state.resources = resources
            // }
        },
    },
    state: {
        message: null,
        status: null,
        loginStatus: localStorage.getItem('loginStatus') ? localStorage.getItem('loginStatus') : 1,
        codeId: sessionStorage.getItem('codeId') ? sessionStorage.getItem('codeId') : '',
        resources: localStorage.getItem('resources') ? localStorage.getItem('resources') : null,
        token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
        isAdminLogin: localStorage.getItem('isAdminLogin') ? localStorage.getItem('isAdminLogin') : false,
        isUserLogin: localStorage.getItem('isUserLogin') ? JSON.parse(localStorage.getItem('isUserLogin')) : false
        // isAdminLogin: false
    },
    getters: {

    }
}
