// 必须引入封装好的 axios 实例方法,才可发请求
import { reqGetMyUserinfo } from '../../api/index'
export default {
    namespaced: true,
    actions: {
        async getMyUserinfo({ commit }) {
            let result = await reqGetMyUserinfo()
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', "获取个人信息成功!")
                commit('GETMYUSERINFO', result.data)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
    },
    mutations: {
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        GETMYUSERINFO(state, myUserInfo) {
            localStorage.setItem('myUserInfo', JSON.stringify(myUserInfo))
            state.myUserInfo = myUserInfo
        }
    },
    state: {
        message: null,
        status: null,
        myUserInfo: localStorage.getItem('myUserInfo') || null
    },
    getters: {

    }
}
