// 必须引入封装好的 axios 实例方法,才可发请求
import {
    // reqGetArticleA,
    reqCategoryA,
    reqBannerA,
    reqNewsA,
    reqDemeanourA,
    reqRecruitmentA
} from '../../api/index'
import formatDate from "../../plugins/formatDate";
export default {
    namespaced: true,
    actions: {
        async getBannerA({ commit }) {
            let result = await reqBannerA()
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETARTICLELIST', result.data)
                // commit('GETTOTAL', result.total)
                // commit('GETMESSAGE', result.message)
            }
            // else {
            // commit('GETMESSAGE', result.message)
            // }
        },
        async getNewsA({ commit }, data) {
            let result = await reqNewsA(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                // console.log('getNewsA', result.data)
                commit('GETNEWSLIST', result.data)
                // commit('GETTOTAL', result.total)
                // commit('GETMESSAGE', result.message)
            }
            // else {
            // commit('GETMESSAGE', result.message)
            // }
        },
        async getDemeanourA({ commit }, data) {
            let result = await reqDemeanourA(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                // console.log('getDemeanourA', result.data)
                commit('GETDEMEANOURLIST', result.data)
                // commit('GETTOTAL', result.total)
                // commit('GETMESSAGE', result.message)
            } else {
                // commit('GETMESSAGE', result.message)
            }
        },
        // recruitment
        async getRecruitmentA({ commit }, data) {
            let result = await reqRecruitmentA(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                // console.log('reqRecruitmentA', result.data)
                commit('GETRECRUITMENTLIST', result.data)
                // commit('GETTOTAL', result.total)
                // commit('GETMESSAGE', result.message)
            }
            // else {
            // commit('GETMESSAGE', result.message)
            // }
        },
        async getCategory({ commit }) {
            let result = await reqCategoryA()
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETCATEGORYLIST', result.data)
                // commit('GETMESSAGE', result.message)
            }
            // else {
            //     // commit('GETMESSAGE', result.message)
            // }
        },
    },
    mutations: {
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        GETTOTAL(state, articleTotal) {
            sessionStorage.setItem('articleTotal', articleTotal)
            state.articleTotal = articleTotal
        },
        GETARTICLELIST(state, bannerList) {
            sessionStorage.setItem('bannerList', JSON.stringify(bannerList))
            state.bannerList = bannerList
        },
        GETNEWSLIST(state, newsAList) {
            sessionStorage.setItem('newsAList', JSON.stringify(newsAList))
            state.newsAList = newsAList
        },
        GETCATEGORYLIST(state, categoryAList) {
            sessionStorage.setItem('categoryAList', JSON.stringify(categoryAList))
            state.categoryAList = categoryAList
        },
        GETDEMEANOURLIST(state, demeanourAList) {
            sessionStorage.setItem('demeanourAList', JSON.stringify(demeanourAList))
            state.demeanourAList = demeanourAList
        },
        GETRECRUITMENTLIST(state, recruitmentAList) {
            sessionStorage.setItem('recruitmentAList', JSON.stringify(recruitmentAList))
            state.recruitmentAList = recruitmentAList
        },
    },
    state: {
        message: null,
        status: null,
        articleTotal: JSON.parse(sessionStorage.getItem('articleTotal')) || 0,
        categoryAList: JSON.parse(sessionStorage.getItem('categoryAList')) || [],
        bannerList: JSON.parse(sessionStorage.getItem('bannerList')) || [],
        newsAList: JSON.parse(sessionStorage.getItem('newsAList')) || [],
        demeanourAList: JSON.parse(sessionStorage.getItem('demeanourAList')) || [],
        recruitmentAList: JSON.parse(sessionStorage.getItem('recruitmentAList')) || [],
    },
    getters: {
        formattedNewsAList: (state) => {
            return state.newsAList.map((article) => {
              const pub_date = formatDate(article.pub_date);
              const update_date = formatDate(article.update_date);
              const article_create_time = formatDate(article.article_create_time);
              return { ...article, pub_date, update_date, article_create_time };
            });
        },
    }
}
