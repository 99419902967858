// 必须引入封装好的 axios 实例方法,才可发请求
import {
  reqGetArticleB,
  reqGetArticleA,
  reqAddArticle,
  reqUpdateArticle,
  reqPassArticle,
  reqDeleteArticle,
} from "../../api/index";
import formatDate from "../../plugins/formatDate";
import formatTime from "../../plugins/formatTime";
export default {
  namespaced: true,
  actions: {
    async getArticleB({ commit }, data) {
      let result = await reqGetArticleB(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETARTICLELIST", result.data);
        commit("GETTOTAL", result.total);
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getArticleA({ commit }, data) {
      let result = await reqGetArticleA(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETARTICLELIST", result.data);
        commit("GETTOTAL", result.total);
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqAddArticle
    async addArticle({ commit }, data) {
      let result = await reqAddArticle(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqUpdateArticle
    async updateArticle({ commit }, data) {
      let result = await reqUpdateArticle(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqPassArticle
    async passArticle({ commit }, data) {
      // console.log('发送的参数',data)
      let result = await reqPassArticle(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqDeleteArticle
    async deleteArticle({ commit }, data) {
      let result = await reqDeleteArticle(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
  },
  mutations: {
    GETSTATUS(state, status) {
      if (status) {
        state.status = status;
      }
    },
    GETMESSAGE(state, message) {
      if (message) {
        state.message = message;
      }
    },
    GETARTICLELIST(state, articleList) {
      sessionStorage.setItem("articleList", JSON.stringify(articleList));
      state.articleList = articleList;
    },
    GETTOTAL(state, articleTotal) {
      sessionStorage.setItem("articleTotal", articleTotal);
      state.articleTotal = articleTotal;
    },
  },
  state: {
    status: null,
    message: null,
    articleTotal: JSON.parse(sessionStorage.getItem("articleTotal")) || 0,
    articleList: JSON.parse(sessionStorage.getItem("articleList")) || [],
  },
  getters: {
    formattedArticleList: (state) => {
      return state.articleList.map((article) => {
        const pub_date = formatDate(article.pub_date);
        const update_date = formatDate(article.update_date);
        const article_create_time = formatTime(article.article_create_time);
        return { ...article, pub_date, update_date, article_create_time };
      });
    },
  },
};
