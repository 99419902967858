// 必须引入封装好的 axios 实例方法,才可发请求
import {
    reqGetMessageA,
    reqGetMessageB,
    reqAddMessage,
    reqPassMessage,
    reqUpdateMessage,
    reqDeleteMessage
} from '../../api/index'
export default {
    namespaced: true,
    actions: {
        async getMessageA({ commit }, data) {
            let result = await reqGetMessageA(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGELIST', result.data)
                commit('GETMESSAGETOTAL', result.total)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async getMessageB({ commit }, data) {
            let result = await reqGetMessageB(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGELIST', result.data)
                commit('GETMESSAGETOTAL', result.total)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async addMessage({ commit }, data) {
            let result = await reqAddMessage(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async passMessage({ commit }, data) {
            let result = await reqPassMessage(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async updateMessage({ commit }, data) {
            let result = await reqUpdateMessage(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async deleteMessage({ commit }, data) {
            let result = await reqDeleteMessage(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
    },
    mutations: {
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        GETMESSAGELIST(state, messageList) {
            sessionStorage.setItem('messageList', JSON.stringify(messageList))
            state.messageList = messageList
        },
        GETMESSAGETOTAL(state, messageTotal) {
            sessionStorage.setItem('messageTotal', messageTotal)
            state.messageTotal = messageTotal
        }
    },
    state: {
        status: null,
        message: null,
        messageList: JSON.parse(sessionStorage.getItem('messageList')) || [],
        messageTotal: JSON.parse(sessionStorage.getItem('messageTotal')) || null,
    },
    getters: {

    }
}
