import Vue from 'vue'
import App from './App.vue'
// 引入路由器
import router from './router/index'
// 引入Vuex仓库
import store from './store/index'
// 引入element-ui
import './plugins/element-ui.js'
// 引入mavenEditor
// import './plugins/mavonEditor.js'
// 引入 echart
// 引入amap
import './plugins/amap.js'
import './plugins/echarts.js'
// 初始化样式,引入样式顺序要从上往下,越下面的权重越高
import './assets/css/base.css'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
