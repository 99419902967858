// 必须引入封装好的 axios 实例方法,才可发请求
import {
  reqGetAllUserinfoList,
  reqGetUserinfoListByAccountStatus,
  reqGetUserinfoListByEnabledStatus,
  reqGetUserinfoListByEnabledStatusAndAccountStatus,
  reqGetUserinfoListByEnabledStatusAndRoleId,
  reqGetUserinfoListByAccountStatusAndRoleId,
  reqGetUserinfoListByAll,
  reqAddAccount,
  reqUpdateUserinfo,
  reqGetUserinfoListByRoleId,
  reqDeleteAccount,
  reqRecoverAccount,
  reqEnableAccount,
  reqUpdateEmail,
  reqUpdatePassword,
  reqUpdateRole,
} from "../../api/index";
import formatDate from "../../plugins/formatDate";
export default {
  namespaced: true,
  actions: {
    async getAllUserinfoList({ commit }, data) {
      let result = await reqGetAllUserinfoList(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByAccountStatus({ commit }, data) {
      let result = await reqGetUserinfoListByAccountStatus(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByEnabledStatus({ commit }, data) {
      let result = await reqGetUserinfoListByEnabledStatus(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByEnabledStatusAndAccountStatus({ commit }, data) {
      let result = await reqGetUserinfoListByEnabledStatusAndAccountStatus(
        data
      );
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByEnabledStatusAndRoleId({ commit }, data) {
      let result = await reqGetUserinfoListByEnabledStatusAndRoleId(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByAccountStatusAndRoleId({ commit }, data) {
      let result = await reqGetUserinfoListByAccountStatusAndRoleId(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByRoleId({ commit }, data) {
      let result = await reqGetUserinfoListByRoleId(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async getUserinfoListByAll({ commit }, data) {
      let result = await reqGetUserinfoListByAll(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "获取用户信息列表成功!");
        commit("GETUSERINFOLIST", result.data.results);
        commit("GETUSERTOTAL", result.data.userTotal);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async addAccount({ commit }, data) {
      let result = await reqAddAccount(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "添加账号成功!");
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async updateUserinfo({ commit }, data) {
      let result = await reqUpdateUserinfo(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", "修改用户信息成功!");
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async deleteAccount({ commit }, data) {
      let result = await reqDeleteAccount(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async recoverAccount({ commit }, data) {
      let result = await reqRecoverAccount(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    async enableAccount({ commit }, data) {
      let result = await reqEnableAccount(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqUpdateEmail
    async updateEmail({ commit }, data) {
      let result = await reqUpdateEmail(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqUpdatePassword
    async updatePassword({ commit }, data) {
      let result = await reqUpdatePassword(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    // reqUpdateRole
    async updateRole({ commit }, data) {
      let result = await reqUpdateRole(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
  },
  mutations: {
    GETSTATUS(state, status) {
      if (status) {
        state.status = status;
      }
    },
    GETMESSAGE(state, message) {
      if (message) {
        state.message = message;
      }
    },
    GETUSERINFOLIST(state, userInfoList) {
      sessionStorage.setItem("userInfoList", JSON.stringify(userInfoList));
      state.userInfoList = userInfoList;
    },
    GETUSERTOTAL(state, userTotal) {
      sessionStorage.setItem("userTotal", userTotal);
      state.userTotal = userTotal;
    },
  },
  state: {
    message: null,
    status: null,
    userTotal: JSON.parse(sessionStorage.getItem("userTotal")) || null,
    userInfoList: JSON.parse(sessionStorage.getItem("userInfoList")) || null,
  },
  getters: {
    // 在 getters 中定义一个名为 formattedUserInfoList 的计算属性
    formattedUserInfoList: (state) => {
      if (!state.userInfoList) {
        return [];
      }
      // 遍历 userInfoList 数组，将其中的 user_create_time 字段转换为指定格式
      return state.userInfoList.map((userInfo) => {
        const formattedUserInfo = { ...userInfo };
        formattedUserInfo.user_create_time = formatDate(
          userInfo.user_create_time
        );
        // console.log("formattedUserInfo", formattedUserInfo);
        return formattedUserInfo;
      });
    },
  },
};
