<template>
  <div class="coord-picker">
    <div class="map-container">
      <amap cache-key="coord-picker-map" mmap-style="amap://styles/whitesmoke" :center.sync="center" :zoom.sync="zoom"
        is-hotspot @click="onMapClick">
        <amap-scale />

        <!-- <amap-marker :position.sync="position" /> -->
        <amap-marker :position="[120.53400, 31.25750]" :label="{ content: '苏州高新区青山路1号1幢303室', direction: 'top' }" />
        <amap-info-window :visible="!!activeAoi" :position="[120.53400, 31.25750]" :offset="[0, -30]" auto-move
          is-custom>
          <div class="info-window-content" v-if="activeAoi">
            <!-- <a-card shadow="always">
              <a-icon type="close" class="button-close" @click="activeAoi = null" />
              <h3>该点的位置</h3>
              <div>{{ activeAoi.position[0] }}, {{ activeAoi.position[1] }}</div>
            </a-card> -->
          </div>
        </amap-info-window>
      </amap>
    </div>
  </div>
</template>

<script>
// 开发时，暂时这样使用。 发布需要另外配置密钥
window._AMapSecurityConfig = {
  securityJsCode: "374b814c95778acf46758bef81144ba1",
};
const aois = [
  [120.53400, 31.25750]
].map((position, i) => ({
  position,
  comment: `备注-${i}`,
}));
// import { loadAmap } from "@amap/amap-vue";
// import { loadAmap, loadPlugins } from "@amap/amap-vue";
// import { mapActions } from "vuex";

export default {
  name: "Map",
  data() {
    return {
      aois,
      activeAoi: aois[0],
      show: true,
      select: 1,
      // 经纬度：120.56499 , 31.28294 
      // 设置地图初始化时, 默认以新余学院的经纬度为中心; 如果不设置, 则默认ip定位城市
      center: [120.53400, 31.25750],
      zoom: 16,
      position: [120.53400, 31.25750],
      total: 0,
      form: { longitude: null, latitude: null, address: null },
      restaurants: [],
      tips: [],
      results: [],
      pageIndex: 1,
      pageSize: 10,
      district: null,
      address: null,
    };
  },
  computed: {
    // positionText() {
    //   if (!this.position) return null;
    //   this.show = this.position ? true : false;
    //   this.form.longitude = `${this.position[0]}`;
    //   this.form.latitude = `${this.position[1]}`;
    //   return `${this.position[0]}, ${this.position[1]}`;
    // },
  },
  created() {
    this.$ready = new Promise((resolve) => {
      // const AMap = loadAmap();
      // loadPlugins(["AMap.AutoComplete"]);
      // this.ps = new AMap.PlaceSearch({
      //   pageSize: this.pageSize,
      //   pageIndex: this.pageIndex,
      // });
      // this.ac = new AMap.AutoComplete();

      resolve();
    });
  },
  methods: {
    // ...mapActions("addressSet", {
    //   getLongitude: "getLongitude",
    //   getLatitude: "getLatitude",
    //   getAddress: "getAddress",
    //   getCoordinate: "getCoordinate",
    // }),
    onMapComplete(map) {
      map.setFitView();
    },
    onMapClick() {
      this.activeAoi = null;
    },
    onMarkerClick(p) {
      this.activeAoi = p;
    },
    // onMapClick(e) {
    //   if (e.lnglat) {
    //     this.position = [e.lnglat.lng, e.lnglat.lat];
    //   } else {
    //     this.position = null;
    //   }
    // },
    // querySearch(queryString, cb) {
    //   // var restaurants = this.restaurants;
    //   // let results;
    //   // 根据关键字 获取远程服务器的 搜索结果
    //   this.ac.search(queryString, (status, result) => {
    //     if (status === "complete" && result.tips) {
    //       // 调用 callback 返回 搜索结果 建议列表的数据
    //       cb(result.tips);
    //     }
    //   });
    //   // this.ps.search(queryString, function (status, result) {
    //   //   // 查询成功时，result即对应匹配的POI信息
    //   //   console.log(result);
    //   // });
    //   // results = queryString
    //   //   ? restaurants.filter(this.createFilter(queryString))
    //   //   : restaurants;
    // },
    // handleSelect(item) {
    //   this.district = item.district;
    //   this.address = item.address + item.name;
    //   this.form.longitude = item.location.lng;
    //   this.form.latitude = item.location.lat;
    //   this.form.address = item.name;
    //   const pos = [item.location.lng, item.location.lat];
    //   this.position = [...pos];
    //   this.center = [...pos];
    // },
    // confirm() {
    //   if (this.show && this.form.address) {
    //     // this.positionText,// 经纬度
    //     this.getLongitude(this.form.longitude);
    //     this.getLatitude(this.form.latitude);
    //     this.getAddress(this.address);
    //     this.getCoordinate(this.positionText);
    //     this.$emit("toParent", this.address);
    //     // this.show = false;
    //   } else {
    //     this.$message("请填写地址!");
    //   }
    // },
    // cancel() {
    //   this.district = null;
    //   this.address = null;
    //   this.form.longitude = null;
    //   this.form.latitude = null;
    //   this.form.address = null;
    // },
  },
};
</script>

<style lang="less" scoped>
@color: #317bf6;

.right {
  float: right;
}

.left {
  float: left;
}

.map-container {
  width: 100%;
  height: 400px;
  margin: 20px 0;
  overflow: hidden;
}

.info-window-content {
  position: relative;
  width: 220px;

  h3 {
    margin-top: 0;
  }

  .button-close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;

    cursor: pointer;
  }
}

.result-panel {
  font-size: 13px;
  overflow: hidden;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  left: 10px;
  top: 10px;
  width: 422px;
  display: flex;
  flex-direction: column;

  /deep/ .el-form-item__label {
    font-size: 14px !important;
  }

  .none {
    display: none;
  }

  .active {
    display: block;
  }

  .text {
    margin: 10px 10px 0 10px;
    color: #606266;
  }

  .coordinate_box {
    height: 22px;
    padding: 10px 0 10px 10px;
    margin: 0;
    display: flex;

    .coordinate {
      width: 110px;
    }

    .coordinate2 {
      width: 200px;

      .el-select {
        padding: 1px !important;
        width: 90px;
      }
    }

    .coordinate3 {
      width: 400px;

      .el-select {
        padding: 1px !important;
        width: 90px;
      }
    }
  }

  .el-button {
    border: 1px solid @color !important;
    margin: 10px;
  }

  .button_add {
    background: @color;
    border: @color;
    color: #fff;
    border: @color 1px solid;
    // border: 1px solid @color;
  }

  .button_cancel {
    // border: 1px solid @color;
    color: @color;
  }

  .search-bar {
    display: flex;
    align-items: center;

    .text {
      text-overflow: ellipsis;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .result-list.ant-list-loading {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info {
  display: none;
  position: absolute;
  right: 10px;
  top: 200px;
  padding-left: 24px;
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>