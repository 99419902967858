<template>
  <el-container>
    <el-main class="header">
      <el-header :class="{ sticky: isSticky, headerOpen: isActive }">
        <div class="logo" @click="toHome">
          <img src="../../assets/images/home/new-logo.svg" />
          <span>玄瑞科技</span>
        </div>
        <ul class="nav_menu" :class="{ open: isActive }" @click="to">
          <li :class="{ 'slide-in': isActive }" data-name="home">首页</li>
          <li :class="{ 'slide-in': isActive }" data-name="aboutUs">关于我们</li>
          <li v-for="item in categoryAList" :key="item.category_id" :class="{ 'slide-in': isActive }" data-name="articles"
            @click="getArticlesList(item.category_id, item.category_name, item.category_introduction)">
            {{ item.category_name }}
          </li>
          <!-- <li data-name="myuserinfo" v-show="isUserLogin">个人中心</li> -->
          <!-- <li
            v-show="!isUserLogin"
            :class="{ 'slide-in': isActive }"
            data-name="login"
          >
            登录
          </li> -->
          <li data-name="link" data-url="http://scm.8156z.com/">客户平台</li>
          <li data-name="link" data-url="http://tms.8156z.com/">网运系统</li>
          <li data-name="link" data-url="http://wms.8156z.com/">专线管理</li>
        </ul>
        <div class="burger" :class="{ active: isActive }" @click="isActive = !isActive">
          <div class="top-line"></div>
          <div class="middle-line"></div>
          <div class="bottom-line"></div>
        </div>
      </el-header>
    </el-main>
    <el-main class="swiper">
      <!-- <div class="swiper-box" :class="{ showbox: !isSticky }"> -->
      <div class="swiper-box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../../assets/images/home/drive_the_future_with_data.jpg">
            </div>
            <div class="swiper-slide">
              <img src="../../assets/images/home/14461685628451_hd.jpg">
            </div>
            <div class="swiper-slide">
              <img src="../../assets/images/home/14471685628452_hd.jpg">
            </div>
            <!-- <div v-for="item in bannerList" :key="item.article_id" class="swiper-slide">
              <div class="swiper-title">
                <span>{{ item.title }}</span>
              </div>
              <img :src="item.cover_img" />
            </div> -->
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev"></div>
          <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
          <div class="swiper-button-next"></div>
          <!--右箭头。如果放置在swiper外面，需要自定义样式。-->

          <!-- 如果需要滚动条 -->
          <!-- <div class="swiper-scrollbar"></div> -->
        </div>
      </div>
    </el-main>

    <el-main class="production">
      <div class="title">
        优质的产品
      </div>
      <span class="introduction">五大智慧管理平台</span>
      <div class="main-content">
        <div class="main-content-item">
          <img class="main-content-img" src="../../assets/images/home/cleant.svg" />
          <span>
            <div class="main-content-title">客户平台</div>
            <div class="main-content-introduction">通过客户平台，一键发布物流订单，实时查询在途、待收货物状态与位置信息，与客户信息共享，降低企业成本，提高供应链、物流运营效率</div>
          </span>
        </div>
        <div class="main-content-item">
          <img class="main-content-img" src="../../assets/images/home/service.svg" />
          <span>
            <div class="main-content-title">网运平台</div>
            <div class="main-content-introduction">通过网运平台，一键发布货源，快速匹配附近货车资源，享优质、优价车辆服务；并享全国优质配载站、精品物流专线联配、联送服务支持</div>
          </span>
        </div>
        <div class="main-content-item">
          <img class="main-content-img" src="../../assets/images/home/qrcode.jpg" />
          <span>
            <div class="main-content-title">运瞳软件</div>
            <div class="main-content-introduction">运瞳平台，专业互联网+物流+供应链服务平台，欢迎关注！</div>
          </span>
        </div>
        <div class="main-content-item">
          <img class="main-content-img" src="../../assets/images/home/group.svg" />
          <span>
            <div class="main-content-title">专线平台</div>
            <div class="main-content-introduction">运瞳专线平台，提供公路干线运输，城市配送及站点仓储服务；以线路为产品，双驾驶员、全程高速、加长集装箱甩挂运输，时效快捷、价格低廉</div>
          </span>
        </div>
        <div class="main-content-item">
          <img class="main-content-img" src="../../assets/images/home/drive.svg" />
          <span>
            <div class="main-content-title">车主平台</div>
            <div class="main-content-introduction">运瞳车主平台，专为个体货运车主提供优质货源与车辆管理的平台；旨在增加车主收益，高效配车并减少空驶，降低车辆成本，在线快速结算</div>
          </span>
        </div>
      </div>
    </el-main>

    <el-main class="news">
      <div class="title" v-if="categoryAList[0]">
        {{ categoryAList[0].category_name }}
      </div>
      <span class="introduction">{{ categoryAList[0].category_introduction }}</span>
      <div class="main-content">
        <div class="content-box" v-for="(item, index) in formattedNewsAList" :key="index" v-show="index < 5"
          @click="toArticle(item.article_id)">
          <img :src="item.cover_img" />
          <div class="content-box-right">
            <span class="content-box-right-title">
              {{ item.title }}
            </span>
            <span class="content-box-right-introduction">
              {{ item.introduction }}
            </span>
            <span class="content-box-right-pub_date">
              {{ item.pub_date }}
            </span>
          </div>
        </div>
        <!-- <div class="content-box" v-if="newsAList[1]">
          <div class="content" @click="toArticle(newsAList[1].article_id)" v-if="newsAList[1]">
            <div class="content-box-title">
              {{ newsAList[1].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[2].article_id)" v-if="newsAList[2]">
            <div class="content-box-title">
              {{ newsAList[2].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[3].article_id)" v-if="newsAList[3]">
            <div class="content-box-title">
              {{ newsAList[3].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[4].article_id)" v-if="newsAList[4]">
            <div class="content-box-title">
              {{ newsAList[4].title }}
            </div>
          </div>
        </div>
        <div class="content-box" v-if="newsAList[5]">
          <div class="content" @click="toArticle(newsAList[5].article_id)" v-if="newsAList[5]">
            <div class="content-box-title">
              {{ newsAList[5].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[6].article_id)" v-if="newsAList[6]">
            <div class="content-box-title">
              {{ newsAList[6].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[7].article_id)" v-if="newsAList[7]">
            <div class="content-box-title">
              {{ newsAList[7].title }}
            </div>
          </div>
          <div class="content" @click="toArticle(newsAList[8].article_id)" v-if="newsAList[8]">
            <div class="content-box-title">
              {{ newsAList[8].title }}
            </div>
          </div>
        </div>
        <div class="content-box" @click="toArticle(newsAList[9].article_id)" v-if="newsAList[9]">
          <img :src="newsAList[9].cover_img" />
          <div class="content-box-bootom">
            {{ newsAList[9].title }}
          </div>
        </div> -->
      </div>
    </el-main>
    <!-- <el-main class="main2">
      <div class="title" v-if="categoryAList[1]">
        {{ categoryAList[1].category_name }}
      </div>
      <ul class="demeanour-content-box">
        <li
          v-for="item in demeanourAList"
          :key="item.article_id"
          class="demeanour-content"
          @click="toArticle(item.article_id)"
        >
          <img class="demeanour" :src="item.cover_img" />
          <div class="content-box-bootom">
            {{ item.title }}
          </div>
        </li>
      </ul>
    </el-main>
    <el-main class="main3">
      <div class="title" v-if="categoryAList[2]">
        {{ categoryAList[2].category_name }}
      </div>
      <ul class="recruitment-box">
        <li
          v-for="item in recruitmentAList"
          :key="item.article_id"
          @click="toArticle(item.article_id)"
          class="recruitment"
        >
          <div class="recruitment-title">
            {{ item.title }}
          </div>
          <div class="recruitment-introduction">
            {{ item.introduction }}
          </div>
        </li>
      </ul>
    </el-main> -->

    <div class="to-top" :class="{ show: isSticky }" @click="toTop">TOP</div>

    <el-footer>
      <!-- <div class="footer-top">
        <ul class="nav_menu" @click="to">
          <li data-name="home">首页</li>
          <li
            v-for="item in categoryAList"
            :key="item.category_id"
            data-name="articles"
            @click="getArticlesList(item.category_id, item.category_name)"
          >
            {{ item.category_name }}
          </li>
        </ul>
      </div> -->
      <div class="footer-bottom">
        <div class="footer-logo">
          <img src="../../assets/images/home/bottom-logo.svg" alt="logo" />
          <span>玄瑞科技</span>
        </div>
        <ul class="footer-content">
          <li>版权所有© 苏州玄瑞信息科技有限公司</li>
          <li>服务热线：0512-66398856</li>
          <!-- <li>地址：苏州高新区青山路1号1幢303室</li> -->
          <!-- <li>苏公网安备32050602011432号•苏ICP备16047967号-1</li> -->
          <li>
            <img src="../../assets/images/nismsp.png" style="width: 16px;padding-right: 4px;"  />
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32050602013280" rel="noreferrer" target="_blank">苏公网安备32050602013280</a>
          </li>
          <li>
            <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备16047967号-9</a>
          </li>
          <!-- <li>联系电话：</li> -->
        </ul>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      isSticky: false,
      isActive: false,
    };
  },
  computed: {
    ...mapState("login", ["isUserLogin"]),
    ...mapState("home", [
      "bannerList",
      "newsAList",
      "demeanourAList",
      "categoryAList",
      "status",
      "recruitmentAList",
    ]),
    ...mapState("articles", ["categoryId"]),
    ...mapGetters("home", ["formattedNewsAList"]),
  },
  methods: {
    ...mapActions("home", {
      getBannerA: "getBannerA",
      getCategory: "getCategory",
      getNewsA: "getNewsA",
      getDemeanourA: "getDemeanourA",
      getRecruitmentA: "getRecruitmentA",
    }),
    // ...mapActions("articles", { getArticleA: "getArticleA" }),
    ...mapMutations("articles", {
      CHANGECATEGORYID: "CHANGECATEGORYID",
      CHANGECATEGORYNAME: "CHANGECATEGORYNAME",
      CHANGECATEGORYINTRODUCTION: "CHANGECATEGORYINTRODUCTION"
    }),
    handleScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      setTimeout(() => {
        if (scrollTop > 0) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      }, 0);
    },
    toArticle(articleId) {
      // /article?articleId=" + articleId
      this.$router.push({ path: "/article?articleId=" + articleId });
      this.toTop();
      // console.log(articleId);
    },
    getArticlesList(categoryId, categoryName, categoryIntroduction) {
      // 获取文章分类参数
      // console.log(categoryId, categoryName);
      this.CHANGECATEGORYID(categoryId);
      this.CHANGECATEGORYNAME(categoryName);
      this.CHANGECATEGORYINTRODUCTION(categoryIntroduction);
      // this.getArticleA({
      //   currentPage: 1,
      //   pageSize: 10,
      //   articleId: 0,
      //   categoryId: this.categoryId,
      // });
    },
    toHome() {
      this.$router.push("/home");
      this.toTop();
    },
    to(e) {
      // this.CHANGECURRENTPAGE(1);
      this.isActive = false;
      let name = e.target.dataset.name;
      let elname = e.target.tagName;
      console.log(e.target.tagName)
      // console.log(this.$route.path !== "/home");
      if (elname === 'LI'){
        if (name === "home" || name === "aboutUs" || name === "myuserinfo" || name == "login"){
          this.$router.push("/" + name);
        }
        if (name == 'link'){
          window.open(e.target.dataset.url, '_blank');
        }
        else {
          this.$router.push("/" + name + "?categoryId=" + this.categoryId);
        }
      }

      // if (
      //   elname === "LI" &&
      //   (name === "home" || name === "aboutUs" || name === "myuserinfo" || name == "login")
      // ) {
      //   this.$router.push("/" + name);
      // } else if (elname === "LI") {
      //   this.$router.push("/" + name + "?categoryId=" + this.categoryId);
      // }
      // setTimeout(() => {
      //   if (name === "category") {
      //     this.CHANGEISSHOW(false);
      //   }
      // }, 1);
      this.toTop();
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getBannerA({
      currentPage: 1,
      pageSize: 6,
      articleId: 0,
      categoryId: 0,
    }).then(() => {
      // if (this.status == 200) {
      setTimeout(() => {
        new Swiper(".swiper-container", {
          // direction: "vertical", // 垂直切换选项, 默认水平, 注释就为水平
          loop: true, // 循环模式选项

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            //修改分页器样式
            bulletClass: "swiper-pagination-bullet",
            //激活样式
            bulletActiveClass: "my-bullet-active",
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          // 如果需要滚动条
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
          // 鼠标滚轮
          // mousewheel: true,

          // 滑动动效
          // effect: "fade",
          // 自动切换
          autoplay: {
            delay: 5000, //5秒切换一次
          },
        });
      }, 500);
      // }
    });
    this.getCategory().then(() => {
      // if (this.categoryAList) {
      // console.log(this.categoryAList);
      // console.log("newsAList", !this.newsAList, this.newsAList.length);
      // if (!this.newsAList.length) {
      this.getNewsA({
        categoryId: this.categoryAList[0].category_id,
      })
      // .then(() => {
      //   // console.log("demeanourAList", !this.demeanourAList);

      //   // if (!this.demeanourAList.length) {
      //   setTimeout(() => {
      //     this.getDemeanourA({
      //       categoryId: this.categoryAList[1].category_id,
      //     });
      //   }, 500);
      //   // }
      // })
      // .then(() => {
      //   // console.log("recruitmentAList", !this.recruitmentAList);

      //   // if (!this.recruitmentAList.length) {
      //   setTimeout(() => {
      //     this.getRecruitmentA({
      //       categoryId: this.categoryAList[2].category_id,
      //     });
      //   }, 1000);
      //   // }
      // });
      // }
      // }
    });

    // this.getNewsA({
    //   currentPage: 1,
    //   pageSize: 10,
    //   articleId: 0,
    //   categoryId: 678,
    // });
    // // setTimeout(() => {
    // this.getDemeanourA({
    //   currentPage: 1,
    //   pageSize: 6,
    //   articleId: 0,
    //   categoryId: 679,
    // });
    // // getRecruitmentA
    // this.getRecruitmentA({
    //   currentPage: 1,
    //   pageSize: 6,
    //   articleId: 0,
    //   categoryId: 680,
    // });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
body {
  color: #333;
  margin: 0;
  padding: 0;
  font-size: 16px;
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.el-container {
  padding: 0;
  margin: 0;
  background: rgb(185, 185, 185);

  .header {
    // position: -webkit-sticky;
    // position: sticky;
    z-index: 999;
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(179, 190, 188);
    overflow: hidden;

    .el-header {
      // padding: 0;
      // margin: 0;
      // background: rgb(125, 178, 190);
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 5vw;
      color: #fff;
      background-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
      transition: 0.3s;

      .logo {
        cursor: pointer;
        position: relative;
        max-height: 5vh;
        line-height: 5vh;
        // max-width: 5vh;
        font-weight: 500;
        color: #fff;
        font-size: 2em;
        text-transform: uppercase; // 字母小写转大写
        letter-spacing: 2px;
        transition: 0.6s;
        display: flex;
        flex: 1;

        img {
          // max-width: 5vh;
          max-height: 5vh;
        }
      }

      .nav_menu {
        position: relative;
        display: flex;
        justify-content: right;
        align-items: center;
        flex: 1;
        max-width: 1000px;

        li {
          cursor: pointer;
          position: relative;
          width: 100%;
          width: 90px;
          text-align: center;
          letter-spacing: 2px;
          font-weight: 500;
          transition: 0.3s;

          &:hover {
            opacity: 0.8;
            // color: rgb(138, 1, 1);
            color: rgb(73, 205, 252);
            // color: #333;
          }
        }
      }

      .burger {
        cursor: pointer;
        display: none;

        div {
          width: 25px;
          height: 4px;
          background: #fff;
          margin: 4px;
          transition: 0.3s ease-in-out 0.3s;
        }
      }

      .active {
        transform: translateX(0);

        div {
          transition: 0.3s ease-in-out 0.3s;
        }

        .top-line {
          transform: rotate(45deg) translate(5px, 6px);
        }

        .bottom-line {
          transform: rotate(-45deg) translate(5px, -6px);
        }

        .middle-line {
          opacity: 0;
          transform: translateX(50px);
          transition: 0.3s ease-in-out;
        }
      }

      @media screen and (max-width: 768px) {
        .logo {
          img {
            max-width: 5vh;
          }
        }

        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transform: translateX(100%);
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            line-height: 50px;
            width: 100%;
            transform: translateX(20px);
            // color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .burger {
          display: block;
        }

        .open {
          transform: translateX(0);
        }
      }
    }

    .headerOpen {
      background: #333;
      opacity: 0.8;
    }

    // .swiper-box {
    //   transition: 0.4s;
    //   // height: 0;
    //   // height: 800px;
    //   height: 100vh;
    //   overflow: hidden;
    //   width: 100%;

    //   .swiper-container {
    //     width: 100vw;
    //     height: 100vh;
    //     margin: 0 auto;
    //     // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
    //     --swiper-navigation-color: rgba(159,
    //         170,
    //         174,
    //         0.8);

    //     /* 单独设置按钮颜色 */
    //     // --swiper-navigation-size: 30px; /* 设置按钮大小 */
    //     .swiper-wrapper {
    //       cursor: pointer;
    //     }

    //     .swiper-button-prev,
    //     .swiper-button-next {
    //       transition: 0.3s;

    //       &:hover {
    //         --swiper-navigation-color: rgb(255, 255, 255);
    //       }
    //     }

    //     .swiper-pagination-bullet {
    //       width: 20px;
    //       height: 20px;
    //       background: #333;
    //       opacity: 0.8;
    //     }

    //     /deep/ .my-bullet-active {
    //       background: #ffffff;
    //       opacity: 1;
    //     }

    //     img {
    //       width: 100vw;
    //       height: 100vh;
    //       margin: 0 auto;
    //     }

    //     .swiper-title {
    //       position: absolute;
    //       top: 90vh;
    //       color: #fff;
    //       font-size: 24px;
    //       height: 10vh;
    //       width: 100vw;
    //       text-align: left;
    //       display: flex;
    //       justify-content: space-around;
    //       text-align: center;
    //       // align-items: center;
    //       // background: var(--swiper-navigation-color);
    //       background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
    //       // float: left;
    //       // z-index: 10;
    //     }
    //   }
    // }

    .showbox {
      height: 100vh;
    }

    .sticky {
      height: 50px;
      padding: 5px 2vw;
      // background: #fff;
      // color: rgb(52, 52, 52);
      background: #fff;
      color: #222222;

      .logo {
        color: #222222;

        img {
          max-width: 5vh;
        }
      }

      .burger {
        div {
          background: #333;
        }
      }

      @media screen and (max-width: 768px) {
        .nav_menu {
          position: absolute;
          top: 80px;
          right: 0;
          bottom: 0;
          width: 50vw;
          height: calc(100vh);
          background: rgb(59, 59, 59);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transition: 0.4s ease-in-out;

          li {
            opacity: 1;
            height: 50px;
            width: 100%;
            line-height: 50px;
            color: #333;
            opacity: 0;

            &:hover {
              opacity: 0.8;
              color: #333;
              background: rgb(230, 230, 230);
            }
          }
        }

        .open {
          width: 40vw;
          top: 60px;
          background: #fff;
          opacity: 1;
        }
      }
    }
  }

  .swiper {
    padding: 0;

    .swiper-box {
      transition: 0.4s;
      // height: 0;
      // height: 800px;
      height: 65vh;
      overflow: hidden;
      width: 100%;

      .swiper-container {
        width: 100%;
        // width: 100vw;
        // height: 100vh;
        height: 65vh;
        margin: 0 auto;
        // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
        --swiper-navigation-color: rgba(159,
            170,
            174,
            0.8);

        /* 单独设置按钮颜色 */
        // --swiper-navigation-size: 30px; /* 设置按钮大小 */
        .swiper-wrapper {
          cursor: pointer;
        }

        .swiper-button-prev,
        .swiper-button-next {
          margin: 10px;
          transition: 0.3s;

          &:hover {
            --swiper-navigation-color: rgb(255, 255, 255);
          }
        }

        .swiper-pagination-bullet {
          width: 20px;
          height: 20px;
          background: #333;
          opacity: 0.8;
        }

        /deep/ .my-bullet-active {
          background: #ffffff;
          opacity: 1;
        }

        img {
          width: 100vw;
          height: 65vh;
          margin: 0 auto;
        }

        // .swiper-title {
        //   position: absolute;
        //   top: 90vh;
        //   color: #fff;
        //   font-size: 24px;
        //   height: 10vh;
        //   width: 100vw;
        //   text-align: left;
        //   display: flex;
        //   justify-content: space-around;
        //   text-align: center;
        //   // align-items: center;
        //   // background: var(--swiper-navigation-color);
        //   background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
        //   // float: left;
        //   // z-index: 10;
        // }
      }
    }
  }

  .production {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    overflow: hidden;
    // height: 100%;
    padding-top: 10vh;

    .title {
      // padding-top: 20vh;
      // margin: 10vh;
      // margin-top: 15vh;
      margin-bottom: 10px;
      font-size: 4vh;
      // border-bottom: 2px solid rgb(49, 152, 255);
      color: rgb(51, 51, 51);
      font-size: 24px;
    }

    .introduction {
      color: rgb(185, 185, 185);
      font-size: 14px;
    }

    .main-content {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
      overflow: hidden;
      padding-top: 5vh;

      .main-content-item {
        height: 38vh;
        max-height: 300px;
        width: 20vh;
        max-width: 690px;
        margin: 25px 10px;
        text-align: center;

        .main-content-img {
          width: 10vh;
        }

        .main-content-title {
          color: #222222;
          line-height: 25px;
          margin: 5px 0;
        }

        .main-content-introduction {
          color: rgb(153, 153, 153);
          font-size: 14px;
        }
      }
    }
  }

  .news {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    overflow: hidden;
    // height: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;

    .title {
      // padding-top: 20vh;
      // margin: 10vh;
      // margin-top: 15vh;
      margin-bottom: 10px;
      // font-size: 4vh;
      // border-bottom: 2px solid rgb(49, 152, 255);
      color: rgb(51, 51, 51);
      font-size: 24px;
    }

    .introduction {
      color: rgb(185, 185, 185);
      font-size: 14px;
      margin-bottom: 5vh;
    }

    .main-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 690px;
        // height: 388px;
        // height: 30vh;
        // height: 100%;
        height: 12vh;
        max-height: 360px;
        min-height: 100px;
        width: 90vw;
        max-width: 1200px;
        // background: #ded9d9;
        margin: 25px 10px;

        // box-shadow: 0 2px 5px rgba(170, 166, 166, 0.8);
        // overflow: hidden;
        // border: 1px solid rgb(138, 1, 1);
        // &:nth-child(1),
        // &:nth-child(4) {
        cursor: pointer;
        // position: relative;
        overflow: hidden;

        // box-shadow: 0 2px 5px rgba(170, 166, 166, 0.8);
        img {
          min-width: 25%;
          height: 100%;
          background: #c8c8c8;
          border-radius: 5px;
        }

        .content-box-right {
          // position: absolute;
          // transform: translateY(-100%);
          // height: 60px;
          width: 100%;
          color: #333;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: left;
          flex-direction: column;
          overflow: hidden;

          // background-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
          .content-box-right-title {
            font-size: 16px;
            color: #333;
            text-decoration: none;
            white-space: normal;
            display: inline-block;
            overflow: hidden;
          }

          .content-box-right-introduction {
            height: 26px;
            line-height: 26px;
            color: #878787;
            white-space: normal;
            margin-top: 14px;
            font-size: 14px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            word-break: break-word;
            word-wrap: break-word;
          }

          .content-box-right-pub_date {
            line-height: 1.5;
            color: #999;
            display: inline-block;
            font-size: 14px;
            margin-right: 10px;
            margin-top: 10px;
            vertical-align: middle;
            white-space: nowrap;
          }
        }

        // }

        // &:nth-child(2),
        // &:nth-child(3) {
        //   box-shadow: none;
        // }

        .content {
          cursor: pointer;
          width: 49%;
          // width: 20vw;
          height: 20vw;
          max-height: 175px;
          background: #333;
          // box-shadow: 0 2px 5px rgba(170, 166, 166, 0.8);
          overflow: hidden;

          // max-width: 690px;
          // &:nth-child(3),
          // &:nth-child(4) {
          //   margin-top: 10px;
          // }

          .content-box-title {
            height: 19vw;
            max-height: 131px;
            padding: 20px;
          }

          &::after {
            display: block;
            content: " ";
            width: 100%;
            height: 4px;
            background: rgb(138, 1, 1);
          }
        }
      }
    }
  }

  .to-top {
    position: fixed;
    opacity: 0;
    width: 5vh;
    height: 5vh;
    line-height: 5vh;
    margin: 2vw;
    border-radius: 5%;
    box-shadow: 0 2px 5px rgb(1, 113, 138);
    border: 1px solid rgb(1, 113, 138);
    text-align: center;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: 0.6s;
    transform: translateY(10vh);
    // background: #fff;
    background: rgba(1, 113, 138, 0.6);
    color: #fff;
    cursor: pointer;

    &:hover {
      // background: #fff;
      // color: rgb(0, 0, 0);
      // color: #333;
      background: rgb(1, 113, 138);
      box-shadow: 0 0 2vh rgb(1, 113, 138);
    }
  }

  .show {
    opacity: 1;
    transform: translateY(0vh);
    display: block;
  }

  .el-footer {
    padding: 0;
    margin: 0;
    background: #222222;
    color: #8d8d8d;
    height: 20vh !important;
    // height: 300px !important;
    text-align: center;

    // .footer-top {
    //   .nav_menu {
    //     display: flex;
    //     background: rgb(1, 120, 138);
    //     align-items: center;
    //     justify-content: center;
    //     height: 5vh;
    //     overflow: hidden;
    //     li {
    //       width: 80px;
    //       height: 5vh;
    //       line-height: 5vh;
    //       color: #fff;
    //       transition: 0.1s;
    //       cursor: pointer;
    //       &:hover {
    //         opacity: 0.8;
    //         color: #222222;
    //         background: rgb(230, 230, 230);
    //       }
    //     }
    //   }
    // }
    .footer-bottom {
      background: #222222;
      color: #8d8d8d;

      .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;

        img {
          height: 5vh;
          cursor: pointer;
        }
      }

      .footer-content {
        display: flex;
        // background: #302a29;
        // background: rgb(138, 1, 1);
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 5vh;
        overflow: hidden;

        li {
          margin: 10px;
          height: 5vh;
          line-height: 5vh;
          color: #8d8d8d;
          // color: #333;
          transition: 0.1s;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            // color: #333;
            // color: #fff;
            // background: rgb(230, 230, 230);
          }
          a {
            opacity: 0.8;
            color: #ddd;
          }
        }
      }
    }
  }

  // 定义一个动画: 导航li标签 滑入动画 (至少设置一个形参name)
  .slide-in (@name) {
    @keyframes @name {
      0% {
        transform: translateX(20px);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  // 调用动画 (传入实参name)
  .slide-in(slideIn);

  // 定义形参
  .animation(@animation-name, @animation-duration, @animation-timing-function, @animation-delay, @play-state, @animation-iteration-count) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    -ms-animation: @arguments;
    -o-animation: @arguments;
    animation: @arguments;
  }

  // 执行
  .slide-in {
    .animation(slideIn, 0.3s, linear, 0.5s, forwards, 1);
  }
}
</style>

