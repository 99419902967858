// 二次封装axios
import axios from 'axios'
// 引入进度条
import nprogress from 'nprogress'
// 引入进度条样式文件
import 'nprogress/nprogress.css'

// axios对象的create方法创建axios实例
// requests就是axios的一个实例
const requests = axios.create({
    // 配置对象
    // 基础路径,发请求的时候,路径当中会出现 baseURL
    // baseURL: '/code',
    // baseURL: 'http://127.0.0.1:3007',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;'
    },
    // 请求超时时间,5s内没响应就失败
    timeout: 5000,
})

// 请求拦截器:发请求前,请求拦截器可以监测到,可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
    // 进度条开始动
    nprogress.start();
    // 设置统一的请求头：如何配置访问有token的接口
    if (localStorage.token) {
        config.headers.Authorization = localStorage.token
    }

    return config;
}, error => {
    // 请求失败的回调函数
    return Promise.reject(error)
})

// 响应拦截器
requests.interceptors.response.use((res) => {
    // 进度条走完
    nprogress.done();
    return res.data;
}, (error) => {
    // 响应失败的回调函数
    // nprogress.done();
    return Promise.reject(error)
    // return Promise.reject(new Error('faile'));
})

export default requests;