// 统一管理API
import requests from "./axios";
// 把参数转换格式 需要 qs
import Qs from 'qs'
// 简写方法一
// /product/getBaseCategoryList 接口 get 无参
// export const reqCategoryList = () => requests({
//     url: '/product/getBaseCategoryList',
//     method: 'get'
// })
// 简写方法二
// export const reqCategoryList = () => requests.get(`/product/getBaseCategoryList`)

// 上传图片
export const reqUpload = (data) => requests({
    headers: { 'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem('token') },
    url: '/upload/img',
    method: 'post',
    data: data
})

// 发送验证码接口 post - email
export const reqCode = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/code',
    method: 'post',
    // 转换格式,不然后端接收不到
    data: Qs.stringify(data)
})

// 用户登录接口 post
export const reqUserLogin = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/user/userLogin',
    method: 'post',
    // 转换格式,不然后端接收不到
    data: Qs.stringify(data)
})

// 管理登录接口 post
export const reqAdminLogin = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/user/adminLogin',
    method: 'post',
    // 转换格式,不然后端接收不到
    data: Qs.stringify(data)
})

// 注册接口 post
export const reqRegister = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/user/register',
    method: 'post',
    data: Qs.stringify(data)
})

// 忘记密码接口 post
export const reqForgetPassowrd = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/user/forgetPassowrd',
    method: 'post',
    data: Qs.stringify(data)
})

// 获取登录用户的个人信息
export const reqGetMyUserinfo = () => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getMyUserinfo',
    method: 'post',
})

// 获取用户信息列表
// 后台 enabledStatus:0-禁用；1-启用 --- accountStatus:0-申请删除中
// /userinfo/getUserinfoList/1/10/3/3/0
// 获取所有
export const reqGetAllUserinfoList = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/3/3/0',
    method: 'post',
})
// 获取账号状态
export const reqGetUserinfoListByAccountStatus = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/3/' + data.accountStatus + '/0',
    method: 'post',
})
// 获取禁用状态
export const reqGetUserinfoListByEnabledStatus = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/' + data.enabledStatus + '/3/0',
    method: 'post',
})
//  获取账号状态和禁用状态
export const reqGetUserinfoListByEnabledStatusAndAccountStatus = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/' + data.enabledStatus + '/' + data.accountStatus + '/0',
    method: 'post',
})
//  角色
export const reqGetUserinfoListByRoleId = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/3/3/' + data.roleId,
    method: 'post',
})
//   禁用状态 角色
export const reqGetUserinfoListByEnabledStatusAndRoleId = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/' + data.enabledStatus + '/3/' + data.roleId,
    method: 'post',
})
//   账号状态  角色
export const reqGetUserinfoListByAccountStatusAndRoleId = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/3/' + data.accountStatus + '/' + data.roleId,
    method: 'post',
})
//   账号状态  角色
export const reqGetUserinfoListByAll = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/getUserinfoList/' + data.currentPage + '/' + data.pageSize + '/' + data.enabledStatus + '/' + data.accountStatus + '/' + data.roleId,
    method: 'post',
})

// 后台添加账号
export const reqAddAccount = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/account/add',
    method: 'post',
    data: Qs.stringify(data)
})

// 更新用户信息
export const reqUpdateUserinfo = (data) => requests({
    headers: { 'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem('token') },
    url: '/userinfo/updateUserinfo',
    method: 'put',
    data: data instanceof FormData ? data : Qs.stringify(data),
})
// 修改密码 updatePassword
export const reqUpdatePassword = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/updatePassword',
    method: 'put',
    data: Qs.stringify(data),
})
// 修改邮箱 /userinfo/updateEmail
export const reqUpdateEmail = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/userinfo/updateEmail',
    method: 'put',
    data: Qs.stringify(data),
})

// 删除账号 /account/delete
export const reqDeleteAccount = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/account/delete/' + JSON.stringify(data.userIds),
    method: 'delete',
    // data: {userIds:[15]},
    // data: Qs.stringify(data),
})
// 取消删除账号申请 /account/recover
export const reqRecoverAccount = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/account/recover/' + JSON.stringify(data.userIds),
    method: 'put',
})
// /account/enable
// 启用 禁用
export const reqEnableAccount = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/account/enable',
    method: 'post',
    data: Qs.stringify(data),
})
// 设置用户角色
export const reqUpdateRole = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/account/role',
    method: 'post',
    data: Qs.stringify(data),
})
// 获取分类 categoryStatus
export const reqCategory = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/category/getB/' + data.categoryStatus,
    method: 'post',
})
// 获取分类 categoryStatus
export const reqCategoryA = () => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/category/getA',
    method: 'post',
})
// 添加分类 /category/add
export const reqAddCategory = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/category/add',
    method: 'post',
    data: Qs.stringify(data)
})
// 编辑分类 /category/update
export const reqUpdateCategory = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/category/updateCategory',
    method: 'put',
    data: Qs.stringify(data)
})
// 更改分类状态 updateCategoryStatus
export const reqUpdateCategoryStatus = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/category/updateCategoryStatus',
    method: 'put',
    data: Qs.stringify(data)
})
// 删除分类 delete
export const reqDeleteCategory = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/category/delete/' + data.categoryId,
    method: 'delete',
})

// 查询留言 /message/getA/:currentPage/:pageSize/:articleId
export const reqGetMessageA = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: '/message/getA/' + data.currentPage + '/' + data.pageSize + '/' + data.articleId,
    method: 'post',
})
// 查询留言 /message/getB/:currentPage/:pageSize/:messageStatus
export const reqGetMessageB = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/message/getB/' + data.currentPage + '/' + data.pageSize + '/' + data.messageStatus,
    method: 'post',
})
// 添加留言 /message/add
export const reqAddMessage = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/message/add',
    method: 'post',
    data: Qs.stringify(data)
})
// 审核留言申请 /message/pass/:messageIds/:messageStatus JSON.stringify(data.userIds)
export const reqPassMessage = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/message/pass/' + JSON.stringify(data.messageIds) + '/' + data.messageStatus,
    method: 'put',
})
// 修改留言 /message/update
export const reqUpdateMessage = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/message/update',
    method: 'put',
    data: Qs.stringify(data)
})
// 删除留言 /delete/:messageIds JSON.stringify(data.messageIds)
export const reqDeleteMessage = (data) => requests({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/message/delete/' + JSON.stringify(data.messageIds),
    method: 'delete',
})

// 发布文章 /article/add
export const reqAddArticle = (data) => requests({
    headers: { 'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem('token') },
    url: '/article/add',
    method: 'post',
    data: data instanceof FormData ? data : Qs.stringify(data),
})
// 更新文章 /article/update
export const reqUpdateArticle = (data) => requests({
    headers: { 'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem('token') },
    url: '/article/update',
    method: 'put',
    data: data instanceof FormData ? data : Qs.stringify(data),
})
// 批准文章发布申请 /article/pass
export const reqPassArticle = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/article/pass/' + data.articleId + '/' + data.articleStatus,
    method: 'put',
})
// 删除文章 /delete/:articleId
export const reqDeleteArticle = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/article/delete/' + data.articleId,
    method: 'delete',
})
// 查询 /getB/:currentPage/:pageSize/:articleId/:categoryId/:articleStatus
export const reqGetArticleB = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/article/getB/' + data.currentPage + '/' + data.pageSize + '/' + data.articleId + '/' + data.categoryId + '/' + data.articleStatus,
    method: 'post',
})
// /getA/:currentPage/:pageSize/:articleId/:categoryId
export const reqGetArticleA = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded' },
    url: '/article/getA/' + data.currentPage + '/' + data.pageSize + '/' + data.articleId + '/' + data.categoryId,
    method: 'post',
})
// 首页查询文章
export const reqBannerA = () => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded' },
    url: '/article/getA/1/6/0/0',
    method: 'post',
})
// 首页查询文章
export const reqNewsA = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded' },
    // url: '/article/getA/1/10/0/678',
    url: '/article/getA/1/10/0/' + data.categoryId,
    method: 'post',
})
// 首页查询文章
export const reqDemeanourA = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded' },
    // url: '/article/getA/1/6/0/679',
    url: '/article/getA/1/6/0/' + data.categoryId,
    method: 'post',
})
// 首页查询文章
export const reqRecruitmentA = (data) => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded' },
    // url: '/article/getA/1/6/0/680',
    url: '/article/getA/1/6/0/' + data.categoryId,
    method: 'post',
})


// 系统简介
// cateArticleCount
export const reqCateArticleCount = () => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/article/cateArticleCount',
    method: 'get',
})
// pubAndArticleCount
export const reqPubAndArticleCount = () => requests({
    headers: { 'Content-Type': 'multipart/application/x-www-form-urlencoded', Authorization: localStorage.getItem('token') },
    url: '/article/pubAndArticleCount',
    method: 'get',
})
