// 必须引入封装好的 axios 实例方法,才可发请求
import {
  // reqGetArticleB,
  reqGetArticleA,
  /* reqAddArticle,
    reqUpdateArticle,
    reqPassArticle,
    reqDeleteArticle, */
} from "../../api/index";
import formatDate from "../../plugins/formatDate";
export default {
  namespaced: true,
  actions: {
    /* async getArticleB({ commit }, data) {
            let result = await reqGetArticleB(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETARTICLELIST', result.data)
                commit('GETTOTAL', result.total)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        }, */
    async getArticleA({ commit }, data) {
      let result = await reqGetArticleA(data);
      commit("GETSTATUS", result.status);
      if (result.status == 200) {
        commit("GETARTICLELIST", result.data);
        commit("GETTOTAL", result.total);
        commit("GETMESSAGE", result.message);
      } else {
        commit("GETMESSAGE", result.message);
      }
    },
    /* // reqAddArticle
        async addArticle({ commit }, data) {
            let result = await reqAddArticle(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqUpdateArticle
        async updateArticle({ commit }, data) {
            let result = await reqUpdateArticle(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqPassArticle
        async passArticle({ commit }, data) {
            console.log('发送的参数',data)
            let result = await reqPassArticle(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqDeleteArticle
        async deleteArticle({ commit }, data) {
            let result = await reqDeleteArticle(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        }, */
  },
  mutations: {
    GETSTATUS(state, status) {
      if (status) {
        state.status = status;
      }
    },
    GETMESSAGE(state, message) {
      if (message) {
        state.message = message;
      }
    },
    GETARTICLELIST(state, articleAList) {
      sessionStorage.setItem("articleAList", JSON.stringify(articleAList));
      state.articleAList = articleAList;
    },
    GETTOTAL(state, articleATotal) {
      sessionStorage.setItem("articleATotal", articleATotal);
      state.articleATotal = articleATotal;
    },
    CHANGECATEGORYID(state, categoryId) {
      sessionStorage.setItem("categoryId", categoryId);
      state.categoryId = categoryId;
    },
    CHANGECATEGORYNAME(state, categoryName) {
      sessionStorage.setItem("categoryName", categoryName);
      state.categoryName = categoryName;
    },
    CHANGECATEGORYINTRODUCTION(state, categoryIntroduction) {
      sessionStorage.setItem("categoryIntroduction", categoryIntroduction);
      state.categoryIntroduction = categoryIntroduction;
    },
    CHANGECURRENTPAGE(state, currentPage) {
      state.currentPage = currentPage;
      sessionStorage.setItem("currentPage", JSON.stringify(currentPage));
    },
  },
  state: {
    status: null,
    message: null,
    currentPage: JSON.parse(sessionStorage.getItem("currentPage"))
      ? JSON.parse(sessionStorage.getItem("currentPage"))
      : 1,
    categoryName: sessionStorage.getItem("categoryName") || null,
    categoryIntroduction: sessionStorage.getItem("categoryIntroduction") || null,
    categoryId: sessionStorage.getItem("categoryId")
      ? JSON.parse(sessionStorage.getItem("categoryId"))
      : 0,
    articleATotal:
      sessionStorage.getItem("articleATotal") &&
      sessionStorage.getItem("articleATotal") != "undefined"
        ? JSON.parse(sessionStorage.getItem("articleATotal"))
        : 0,
    articleAList: JSON.parse(sessionStorage.getItem("articleAList")) || [],
  },
  getters: {
    pages(state) {
      return Math.ceil(state.articleATotal / 10) !== 0
        ? Math.ceil(state.articleATotal / 10)
        : 1;
    },
    formattedAArticleList: (state) => {
      return state.articleAList.map((article) => {
        const pub_date = formatDate(article.pub_date);
        const update_date = formatDate(article.update_date);
        const article_create_time = formatDate(article.article_create_time);
        return { ...article, pub_date, update_date, article_create_time };
      });
    },
  },
};
