import Vue from 'vue'
import Vuex from 'vuex'
import home from './home'
import login from './login'
import user from './user'
import account from './account'
import category from './category'
import message from './message'
import articles from './articles'
import article from './article'
import backstage from './backstage'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        home,
        login,
        user,
        account,
        category,
        message,
        articles,
        article,
        backstage
    }
})