import * as echarts from 'echarts/core';
import Vue from 'vue';
Vue.prototype.echarts = echarts;
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from 'echarts/components';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { RadarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { LineChart } from 'echarts/charts';
echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    LabelLayout,
    CanvasRenderer,
    PieChart,
    RadarChart,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    UniversalTransition
]);

// var chartDom = document.getElementById('main');
// var myChart = echarts.init(chartDom);
// var option;

// option = {
//   title: {
//     text: 'Referer of a Website',
//     subtext: 'Fake Data',
//     left: 'center'
//   },
//   tooltip: {
//     trigger: 'item'
//   },
//   legend: {
//     orient: 'vertical',
//     left: 'left'
//   },
//   series: [
//     {
//       name: 'Access From',
//       type: 'pie',
//       radius: '50%',
//       data: [
//         { value: 1048, name: 'Search Engine' },
//         { value: 735, name: 'Direct' },
//         { value: 580, name: 'Email' },
//         { value: 484, name: 'Union Ads' },
//         { value: 300, name: 'Video Ads' }
//       ],
//       emphasis: {
//         itemStyle: {
//           shadowBlur: 10,
//           shadowOffsetX: 0,
//           shadowColor: 'rgba(0, 0, 0, 0.5)'
//         }
//       }
//     }
//   ]
// };

// option && myChart.setOption(option);
