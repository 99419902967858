// 必须引入封装好的 axios 实例方法,才可发请求
import {
    reqCategory,
    reqAddCategory,
    reqUpdateCategory,
    reqUpdateCategoryStatus,
    reqDeleteCategory
} from '../../api/index'
import formatTime from "../../plugins/formatTime";
export default {
    namespaced: true,
    actions: {
        async getCategory({ commit }, data) {
            let result = await reqCategory(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETCATEGORYLIST', result.data)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqAddCategory
        async addCategory({ commit }, data) {
            let result = await reqAddCategory(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqUpdateCategory
        async updateCategory({ commit }, data) {
            let result = await reqUpdateCategory(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqUpdateCategoryStatus
        async updateCategoryStatus({ commit }, data) {
            let result = await reqUpdateCategoryStatus(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        // reqDeleteCategory
        async deleteCategory({ commit }, data) {
            let result = await reqDeleteCategory(data)
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
    },
    mutations: {
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        GETCATEGORYLIST(state, categoryList) {
            sessionStorage.setItem('categoryList', JSON.stringify(categoryList))
            state.categoryList = categoryList
        },
    },
    state: {
        status: null,
        message: null,
        categoryList: JSON.parse(sessionStorage.getItem('categoryList')) || []
    },
    getters: {
        formattedCategoryList: (state) => {
            return state.categoryList.map((category) => {
                const category_create_time = formatTime(category.category_create_time);
                return { ...category, category_create_time };
            });
        },
    }
}
