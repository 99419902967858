// 必须引入封装好的 axios 实例方法,才可发请求
import {
    reqCateArticleCount,
    reqPubAndArticleCount
} from '../../api/index'
import formatDate from '../../plugins/formatDate'
export default {
    namespaced: true,
    actions: {
        async getCateArticleCount({ commit }) {
            let result = await reqCateArticleCount()
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETCATEARTICLECOUNT', result.data)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
        async getPubAndArticleCount({ commit }) {
            let result = await reqPubAndArticleCount()
            commit('GETSTATUS', result.status)
            if (result.status == 200) {
                commit('GETPUBANDARTICLECOUNT', result.data)
                commit('GETMESSAGE', result.message)
            } else {
                commit('GETMESSAGE', result.message)
            }
        },
    },
    mutations: {
        GETSTATUS(state, status) {
            if (status) {
                state.status = status
            }
        },
        GETMESSAGE(state, message) {
            if (message) {
                state.message = message
            }
        },
        // GETCATEARTICLECOUNT(state, cateArticleCount) {
        //     sessionStorage.setItem('cateArticleCount', JSON.stringify(cateArticleCount))
        //     state.cateArticleCount = cateArticleCount
        // },
        // GETPUBANDARTICLECOUNT(state, pubAndArticleCount) {
        //     sessionStorage.setItem('pubAndArticleCount', JSON.stringify(pubAndArticleCount))
        //     state.pubAndArticleCount = pubAndArticleCount
        // },
        GETCATEARTICLECOUNT(state, cateArticleCount) {
            state.cateArticleCount = cateArticleCount
            sessionStorage.setItem('cateArticleCount', JSON.stringify(cateArticleCount))
        },
        GETPUBANDARTICLECOUNT(state, pubAndArticleCount) {
            state.pubAndArticleCount = pubAndArticleCount
            sessionStorage.setItem('pubAndArticleCount', JSON.stringify(pubAndArticleCount))
        },
    },
    state: {
        // cateArticleCount: JSON.parse(sessionStorage.getItem('cateArticleCount')) || 0,
        // pubAndArticleCount: JSON.parse(sessionStorage.getItem('cateArticleCount')) || 0,
        status: null,
        message: null,
        pubAndArticleCount: JSON.parse(sessionStorage.getItem('pubAndArticleCount')) ? JSON.parse(sessionStorage.getItem('pubAndArticleCount')) : [],
        cateArticleCount: sessionStorage.getItem('cateArticleCount') != 'undefined' && JSON.parse(sessionStorage.getItem('cateArticleCount')) ? JSON.parse(sessionStorage.getItem('cateArticleCountB')) : [],
    },
    getters: {
        pub_dateAndCount(state) {
            let dates = [], values = []
            for (let i = 0; i < state.pubAndArticleCount.length; i++) {
                let { pub_date, value } = state.pubAndArticleCount[i]
                // console.log(state.pubAndArticleCountA[i])
                pub_date = formatDate(pub_date)
                dates.push(pub_date)
                values.push(value)
            }
            return {
                dates,
                values
            }
        },
        newCateArticleCount(state) {
            // let dates = [], values = []
            let cate_ArticleCount = []
            for (let i = 0; i < state.cateArticleCount.length; i++) {
                let { category_name, value } = state.cateArticleCount[i]
                cate_ArticleCount.push({ name: category_name, value })
            }
            return {
                cate_ArticleCount
            }
        }
    }
}
